import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/app.scss";
import 'jquery-touchswipe';
import fullpage from 'fullpage.js';
import 'fullpage.js/dist/fullpage.css';

var $ = require('jquery');
var jQueryBridget = require('./vendor/jquery-bridget');
var Packery = require('packery');
var imagesLoaded = require('imagesloaded');
var InfiniteScroll = require('infinite-scroll');


imagesLoaded.makeJQueryPlugin($);
jQueryBridget('packery', Packery, $);
/*
jQueryBridget( 'infiniteScroll', InfiniteScroll, $ );
*/
InfiniteScroll.imagesLoaded = imagesLoaded;


// make Packery a jQuery plugin


// When using fullPage extensions replace the previous import
// of fullpage.js for this file
//import fullpage from 'fullpage.js/dist/fullpage.extensions.min';

// Initializing it

const addVHValue = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    const header = document.querySelector('header');
    const hh = header.offsetHeight;

    document.documentElement.style.setProperty("--hh", `${hh}px`);

    window.addEventListener("resize", () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        const hh = header.offsetHeight;
        document.documentElement.style.setProperty("--hh", `${hh}px`);
    });
};

addVHValue();


$(document).ready(function () {

    console.log(navigator.userAgent);

    let scrollableElement = document.querySelector(".scrolling-wrapper");


    scrollableElement?.addEventListener('wheel', (ev) => {
        ev.preventDefault();  // stop scrolling in another direction
        scrollableElement.scrollLeft += (ev.deltaY + ev.deltaX);
    });



    const fullPage = document.querySelector('#fullpage');

    if (fullPage) {
        console.log('frontpage');
        var fullPageInstance = new fullpage('#fullpage', {
            licenseKey: 'gplv3-license',
            scrollOverflow: true,
            onScrollOverflow: function (section, slide, position, direction) {
                if (position > 110) {
                    $('header #logo').fadeOut(100);
                    $('.back-to-top').fadeIn(100);
                }

                if (position < 110) {
                    $('header #logo').fadeIn(100);
                    $('.back-to-top').fadeOut(100);


                }

                if (position > 110) {
                    $('#categories').css("opacity", "1");
                    $(".submenu .categories-list").fadeIn(100);
                    $('.submenu .socials-list').fadeOut(100);
                }

                if (position < 110) {
                    $('#categories').css("opacity", "0");
                }
            }
        });

    }


    const dropdowns = document.querySelectorAll('.dropdown-toggle');
    const dropdownsHeight = [];

    function resizeDropDown(dropdowns) {
        dropdowns.forEach((dropdown) => {
            const container = dropdown.nextElementSibling;
            const height = container.scrollHeight;

            dropdownsHeight.push(height);
        });

        const maxHeight = Math.max(...dropdownsHeight);
        document.documentElement.style.setProperty("--after-height", maxHeight + "px");

    }


    $(window).resize(function () {

        resizeDropDown(dropdowns);
        var ww = $(this).width();


        $('nav #searchform').css("display", "");
        $('#menu-item-6305').css("opacity", "1");
        $('.menu-item').css("opacity", "1");
        $('.menu-item').css("pointer-events", "");

    });

    $(window).trigger("resize");


    if ($('.grid').length) {

        $('.grid').packery({
            // options
            itemSelector: '.grid-item',
            gutter: 0,
            percentPosition: true,
        });


        $('main').imagesLoaded(function (e) {
            $('.grid').packery();
            $('.grid').removeClass("hide-grid")
        });

        // PACKERY GRID


        var pckry = $('.grid').data('packery');
        // AJAX LOADING CATEGORIES

        let elem = document.querySelector('.articles-container');
        let infScroll = new InfiniteScroll(elem, {
            // options
            path: '.pagination__next',
            append: '.post',
            elementScroll: '#top-page',
            scrollThreshold: false,
            loadOnScroll: false,
            checkLastPage: true,
            prefill: false,
            history: false,
            button: '.view-more-button',
            outlayer: pckry,
        });

        infScroll.on('load', function (event, response, path) {
            $('.grid').packery();
        });

        infScroll.on('append', function (event, body, path, items, response) {
            $('.dot-flashing').hide();
            setTimeout(function () {
                $('.view-more-button').show();
            }, 100)
        });

        infScroll.on('last', function (event, body, path) {
            $('.dot-flashing').remove();
            $('.view-more-button').remove();
        });

        infScroll.on('error', function (event, error, path, response) {
            $('.dot-flashing').remove();
            $('.view-more-button').remove();
        });

        $('.view-more-button').click(function () {
            $(this).hide();
            $('.dot-flashing').show();
        });
    }


    if ($(".studio-carousel-wrap .swiper-container").length) {


        var swiperstudio = new Swiper('.studio-carousel-wrap .swiper-container', {
            // Optional parameters
            direction: 'horizontal',
            init: true,
            slidesPerView: 1,
            preventInteractionOnTransition: true,
            centeredSlides: true,
            loop: true,
            preloadImages: false,
            resizeObserver: true,
            lazy: {
                loadPrevNext: true,
                loadPrevNextAmount: 2,
            },
            effect: "slide",
            spaceBetween: 0,
            /*autoplay: {
             delay: 4000,
             },*/
            keyboard: {
                enabled: true,
                onlyInViewport: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            speed: 200,
            simulateTouch: false,
            slideToClickedSlide: true,
            breakpoints: {
                // when window width is >= 320px
                1024: {
                    slidesPerView: "auto",
                    spaceBetween: 50,
                    slidesPerViewFit: false,
                },
            }
        });


        function Update_Swiper_Legend() {
            var titre = $(".swiper-slide-active").data("title");
            var link = $(".swiper-slide-active").data("link");

            $('.swiper-legend').html('<p><a href="' + link + '">' + titre + '</a></p>');
        }

        Update_Swiper_Studio_Legend();

        swiperstudio.on('slideChangeTransitionStart', function () {

            $('.swiper-legend').html("");

        });

        swiperstudio.on('slideChangeTransitionEnd', function () {
            Update_Swiper_Studio_Legend();
        });


        $(".studio-carousel-wrap").on("click", ".swiper-slide-active", function () {
            $('.studio-content').addClass("translateY-100");
            $('#logo').fadeOut(200);
        });

        $(".studio-carousel-wrap").on("click", ".swiper-slide-next", function () {
            swiperstudio.slideNext();
        });

        $(".studio-carousel-wrap").on("click", ".swiper-slide-prev", function () {
            swiperstudio.slidePrev();
        });


        $(".studio-carousel-wrap").on("click", ".swiper-slide-active", function (e) {
            var id_post = $(this).data('id');
            e.preventDefault();
            var postid = $(this).data('id');
            var posturl = $(this).data("link");
            $('.studio-content-inner').hide();
            $('.studio-content-inner').html("");
            // I don't know why the shorthand method had not worked for me as well
            // But this method has worked for me.
            $.ajax({
                type: "POST",
                url: my_ajax_object.ajax_url,
                data: {
                    action: 'my_load_ajax_content',
                    postid: postid,
                }
            }).done(function (data) {
                // Just simple html() method with data will show all the content.
                $('.studio-content-inner').html(data);
                $('.studio-content-inner').fadeIn(300);
                $('.close-btn, .close-btn-xs').show();
                history.pushState(undefined, '', posturl);
            });

            window.onpopstate = function (event) {

                if ($('.studio-content').hasClass("translateY-100")) {
                    $('.studio-content').removeClass("translateY-100");
                    setTimeout(function () {
                        $('#logo').fadeIn(200);
                        $('.close-btn, .close-btn-xs').fadeOut(100);
                    }, 200);
                }
            }
        });


        $('.close-btn, .close-btn-xs').click(function () {
            $('.studio-content').removeClass("translateY-100");
            setTimeout(function () {
                $('#logo').fadeIn(200);
            }, 200);
            $('.close-btn, .close-btn-xs').fadeOut(100);
        });

        function Update_Swiper_Studio_Legend() {
            var titre = $(".swiper-slide-active").data("title");
            var link = $(".swiper-slide-active").data("link");

            $('.swiper-legend').html('<p>' + titre + '</p>');
        }

    } else if ($(".swiper-container").length) {

        var swiper = new Swiper('.swiper-container', {
            // Optional parameters
            direction: 'horizontal',
            init: false,
            loop: true,
            slidesPerView: 1,
            preventInteractionOnTransition: true,
            centeredSlides: true,
            loop: true,
            preloadImages: false,
            lazy: {
                loadPrevNext: true,
                loadPrevNextAmount: 2,
            },
            effect: "slide",
            spaceBetween: 0,
            autoplay: {
                delay: 4000,
            },
            keyboard: {
                enabled: true,
                onlyInViewport: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            speed: 200,
            breakpoints: {
                // when window width is >= 320px
            }
        });

        swiper.on('init', function () {

            $(".home-carousel-wrap").removeClass("hide");
        });

        swiper.init();


        function Update_Swiper_Legend() {
            var titre = $(".swiper-slide-active").data("title");
            var link = $(".swiper-slide-active").data("link");

            $('.swiper-legend').html('<p><a href="' + link + '">' + titre + '</a></p>');
        }

        Update_Swiper_Legend();

        swiper.on('slideChangeTransitionStart', function () {

            $('.swiper-legend').html("");

        });

        swiper.on('slideChangeTransitionEnd', function () {

            Update_Swiper_Legend();

        });
    }


    // MENU BUTTON

    $('.dot').click(function () {

        $('.menu-item').css("opacity", "1").css("pointer-events", "");

        setTimeout(function () {
            $('nav #searchform').css("display", "");
            $('#menu-item-6305').css("opacity", "1");
        }, 300);


        var main_scroll_top = $("main").scrollTop();

        $("nav").toggleClass("show-nav");
        if ($("nav").hasClass("show-nav")) {
            $('header #logo').fadeIn(100);
            $('.back-to-top').fadeOut(100);
        } else {
            if (main_scroll_top == 0) {
                $('header #logo').fadeIn(100);
                if ($('.studio-content').hasClass("translateY-100")) {
                    $('header #logo').fadeOut(100);
                }
            } else {
                $('header #logo').fadeOut(100);
                $('.back-to-top').fadeIn(100);
            }
        }
    });


    // BACK TOP TOP BUTTON

    $('.back-to-top').on('click', function (e) {
        e.preventDefault();
        if ($( "#fullpage" ).length) {
            $('main .fp-overflow').animate({scrollTop: 0}, '300');
        }else{
            $('main').animate({scrollTop: 0}, '300');
        }
    });


    // HOME PAGE PARRALLAX
    /* var first_time = true;

     const homeCarousel = document.querySelector(".home-carousel-wrap");

     if (homeCarousel) {
         homeCarousel.addEventListener('mousewheel', function (e) {

             if (e.deltaY > 0 && first_time == true) {
                 $('main').addClass("translateY-100");
                 $(".home-carousel-wrap").addClass("fade-out");
             }

             e.preventDefault();
             e.stopImmediatePropagation();

         });

         $(".home-carousel-wrap").swipe({
             //Generic swipe handler for all directions
             swipe: function (event, direction, distance, duration, fingerCount, fingerData) {

                 if ($('main').hasClass("translateY-100")) {
                     event.preventDefault();
                 }
                 if (direction == "up") {
                     $('main').addClass("translateY-100");
                     $(".home-carousel-wrap").addClass("fade-out");
                     $(".on-top").bind("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd", function () {
                         $('.home-carousel-wrap').css("transform", "translateY(100%)");
                         $(".on-top").unbind("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd");
                     });
                 }
             }
         });
     }


     $("#top-page").on('scroll', function (event) {

         var scrollTop = ($(this).scrollTop());


         if (scrollTop == 0) {
             $('main').removeClass("translateY-100");
             $('.home-carousel-wrap').css("transform", "translateY(0)");
             $(".home-carousel-wrap").css("height", "");
             $(".home-carousel-wrap").removeClass("fade-out");
         }
     });*/

    /*const topPage = document.querySelector("#top-page");
    topPage?.addEventListener("mousewheel", (e) => {
        if (topPage.scrollTop == 0 && e.deltaY < 0) {
            $('main').removeClass("translateY-100");
            $(".home-carousel-wrap").removeClass("fade-out");
        }
    });

    $("#top-page").swipe({
        swipe: function (event, direction, distance, duration, fingerCount, fingerData) {

            var scrollTop = ($(this).scrollTop());
            $('.home-carousel-wrap').addClass("no-transform");


            if (direction == "down" && scrollTop == 0) {
                $('main').removeClass("translateY-100");
                $(".home-carousel-wrap").removeClass("fade-out");

            } else {
                event.preventDefault();
            }
        }
    });*/


    // LOGO DISPLAY AND CATEGORIES MENU DISPLAY


    $("main").scroll(function () {

        var main_scroll_top = $(this).scrollTop();
        var categories_h = $('#categories').height();

        if (main_scroll_top > 110) {
            $('header #logo').fadeOut(100);
            $('.back-to-top').fadeIn(100);
        }

        if (main_scroll_top < 110) {
            $('header #logo').fadeIn(100);
            $('.back-to-top').fadeOut(100);


        }

        if (main_scroll_top > 110) {
            $('#categories').css("opacity", "1");
            $(".submenu .categories-list").fadeIn(100);
            $('.submenu .socials-list').fadeOut(100);
        }

        if (main_scroll_top < 110) {
            $('#categories').css("opacity", "0");
        }
    });


    // CATGEORIES MENU CLICK

    $(".follow").click(function () {

        if ($(this).hasClass("show")) {
            $(".submenu .categories-list").fadeIn(100);
            $('.submenu .socials-list').fadeOut(100);
            $(this).removeClass("show");
        } else {
            $(this).addClass("show");
            $(".submenu .categories-list").fadeOut(100);
            $('.submenu .socials-list').fadeIn(100);
        }

    });


    // SCROLL TO SECTION SHOP

    $(document).on('click', '.scroll-to-section', function (event) {
        event.preventDefault();

        var mainScrollTop = $('main').scrollTop();

        var ww = window.innerWidth;

        if (ww > 1024) {

            $('main').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - $('.sticky-row').height() * 2 + mainScrollTop
            }, 500);

        } else {
            $('main').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - $('.sticky-row').height() * 3 + mainScrollTop
            }, 500);
        }
    });


    // SEARCH MENU

    $("#menu-item-6305").click(function (e) {
        e.preventDefault();
        $('nav #searchform').css("display", "flex");
        $('nav #searchform input[type="text"]').attr("tabindex", -1).focus();
        $(this).css("opacity", "0");

        if (ww < 1024) {
            $('.menu-item').css("opacity", "0").css("pointer-events", "none");
        }
    });


    // UPDATE WOOCOMMERCE CART

    var timeout;
    jQuery(function ($) {
        $('.woocommerce').on('change', 'input.qty', function () {

            if (timeout !== undefined) {
                clearTimeout(timeout);
            }

            timeout = setTimeout(function () {
                $("[name='update_cart']").trigger("click");
            }, 500); // 1 second delay, half a second (500) seems comfortable too

        });
    });

    // REMOVE UNNECESSARY P TAGS

    $(".single-post-content p").filter(function () {
        return $.trim(this.innerHTML) === "&nbsp;"
    }).remove();


    // UPDATE CART ON CHANGE

});


function SetImageHeight() {

    $('.grid-item-image img').each(function () {

        var w = $(this).attr('width');
        var h = $(this).attr('height');

        var new_w = $(this).width();

        // Landscape
        if (w > h) {

            var ratio = h / w;
            var new_h = new_w * ratio;

            $(this).css("height", new_h);


        }
        // Portrait
        else if (h > w) {

            var ratio = h / w;
            var new_h = new_w * ratio;

            $(this).css("height", new_h);

        }
        // Square
        else {

        }


    });

}

